import { Keyboard } from "@capacitor/keyboard";
import { isDefined } from "@clipboard-health/util-ts";
import { type KeyboardEvent } from "react";

import { isCapacitorPlatform } from "./platformUtils";

export async function hideKeyboardOnPressEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    const { activeElement } = document;
    if (isDefined(activeElement) && activeElement instanceof HTMLElement) {
      activeElement.blur();
    }

    if (isCapacitorPlatform()) {
      Keyboard.hide().catch(() => {
        // Ignore errors
      });
    }
  }
}
