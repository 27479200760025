// Copied from https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useEventCallback/useEventCallback.ts

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useRef } from "react";

export function useEventCallback<Arguments extends unknown[], R>(
  function_: (...arguments_: Arguments) => R
): (...arguments_: Arguments) => R;
export function useEventCallback<Arguments extends unknown[], R>(
  function_: ((...arguments_: Arguments) => R) | undefined
): ((...arguments_: Arguments) => R) | undefined;
export function useEventCallback<Arguments extends unknown[], R>(
  function_: ((...arguments_: Arguments) => R) | undefined
): ((...arguments_: Arguments) => R) | undefined {
  const ref = useRef<typeof function_>(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });

  useEffect(() => {
    ref.current = function_;
  }, [function_]);

  return useCallback((...arguments_: Arguments) => ref.current?.(...arguments_), [ref]) as (
    ...arguments_: Arguments
  ) => R;
}
